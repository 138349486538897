.team {
    margin-bottom: -5px;
    
    &__back {
        position: relative;
        
        img {
            width: 100%;
        }
    }
}