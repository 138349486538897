.intro {
    &__banner {
        img {
            width: 100%;
        }
    }
    &__drop {
        position: relative;
        text-align: center;
        &__back {
            position: relative;
            bottom: -5px;
            width: 100%;
            img {
                width: 100%;
            }
        }
        &__date {
            width: 30%;
            position: absolute;
            transform: translate3d(-50%, -50%, 0);
            left: 48%;
            top: 50%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
            }
            &__mint {
                display: grid;
                grid-template: auto / 1fr 1fr;
                grid-column-gap: 16px;
                grid-row-gap: 24px;
                width: 100%;
                background: #19938d;
                align-items: center;
                justify-items: center;
                border-radius: 15px;
                overflow: hidden;
                padding: 30px;

                @media (max-width : 1024px) {
                    padding: 5px;
                }

                &er {
                    width: 100%;
                    background: #19938d;
                    justify-content: center;
                    align-items: center;
                    column-gap: 5px;
                }
                &__minus {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    img {
                        width: 100%;
                    }
                }
                &__plus {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    img {
                        width: 100%;
                    }
                }
                &__value {
                    font-size: 70px;
                    padding: 0 5%;

                    @media (max-width: 1500px) {
                        font-size: 5vw;
                    }
                }
                &__opensea {
                    padding: 2vw;
                }
                button {
                    background: transparent;
                }
                &__place {
                    text-transform: uppercase;
                    background: #20aba4 !important;
                    padding: 16px 16px 16px 24px;
                    font-size: 5vw;
                    border-radius: 16px;
                    letter-spacing: 5px;
                    font-family: digital;
                    color: white;
                
                    @media (max-width: 1500px) {
                        letter-spacing: 0.33vw;
                    }
                    .svg-inline--fa.fa-w-16 {
                        width: 0.3em;
                    }
                }
            }
            &__price {
                font-size: 3vw;
            }
            &__remain {
                font-size: 3vw;
            }
        }
    }
}

.mint-spinner {
    animation: spin 1s infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}